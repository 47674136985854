import "../styles/App.scss";
import { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navigation from "./Navigation";
import Footer from "./Footer";

import Home from "./Home";
import Services from "./Services";
import AboutMe from "./AboutMe";
import DataPrivacy from "./DataPrivacy";
import Imprint from "./Imprint";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="main">
          <Navigation />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/leistungen" component={Services} />
            <Route exact path="/ueber-mich" component={AboutMe} />
            <Route exact path="/impressum" component={Imprint} />
            <Route exact path="/datenschutz" component={DataPrivacy} />
          </Switch>

          <Footer />
        </div>
      </BrowserRouter> 
    );
  }
}

export default App;
