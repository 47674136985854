import { Component } from "react";
import Banner from "./Banner";
import portrait from "../images/portrait2.png";

class Home extends Component {
  render() {
    return (
      <div className="home">
        <Banner text={"Herzlich Willkommen"} />

        <div className="home-wrapper">
          <div className="portrait-wrapper">
            <img className="fade-in" src={portrait} alt="Portraitbild von Dr. Sylvia Hackl" />
          </div>

          <div className="home-header">
            <h2 className="fade-in">Dr. Sylvia Hackl</h2>
            <div className="fade-in-1 supporting-text">
              Fachärztin für Haut- und Geschlechtskrankheiten
            </div>
          </div>

          <div className="home-information fade-in-2">
            <div className="wr">
              <div>
                <div className="info-wraper">
                  <p>Sehr geehrte Patientinnen und Patienten!</p>
                  <p>Willkommen in unserer Kassenordination.</p>

                  <h3><i className="fa fa-map-marker-alt" /> Adresse</h3>
                  <div>
                    Feschnigstraße 46, 9020 Klagenfurt am Wörthersee
                  </div>

                  <h3><i className="fa fa-clock" /> Öffnungszeiten</h3>
                  <ul>
                    <li>Montag: <b>8 - 13 Uhr</b></li>
                    <li>Dienstag: <b>8 - 13 Uhr</b></li>
                    <li>Mittwoch: <b>13 - 17 Uhr</b></li>
                    <li>Donnerstag: <b>8 - 13 Uhr</b></li>
                    <li>Freitag: <b>8 - 11 Uhr</b></li>
                  </ul>

                  <h3><i className="fa fa-phone" /> Terminvereinbarung</h3>
                  <ul>
                    <li>Per Telefon unter <a href="tel:0463/205950">0463/205950</a></li>
                    <li>Mo, Di, Do und Fr: <b>8 - 11 Uhr</b></li>
                    <li>Mi: <b>13 - 16 Uhr</b></li>
                  </ul>

                  <b> Alles Gute und bleiben Sie gesund.</b>

                  <div class="highlighted-text"> Ihre Sylvia Hackl</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
