import { Component } from "react";
import { withRouter } from "react-router";
import logo from "../images/Logo.png";

class Navigation extends Component {
  state = {
    open: false,
  };

  onNavigate = (e, route) => {
    e.preventDefault();
    this.setState({ open: false });
    this.props.history.push(route);
  };

  getIsActive = (key) => {
    return window.location.pathname === key ? "selected" : "";
  };

  triggerMobileMenu = () => {
    this.setState({ open: !this.state.open });
  };


  render() {
    return (
      <header>
        <div className="logo">
          <div className="logo-img" onClick={(e) => this.onNavigate(e, "/")}>
            <img src={logo} alt="Sylvia Hackl Logo" />
          </div>
          <div onClick={(e) => this.onNavigate(e, "/")}>
            <h2>Dr. Sylvia Hackl</h2>
            <h3>love your skin...</h3>
          </div>

          <button
            className="mobile-menu-trigger"
            onClick={this.triggerMobileMenu}
          >
            {this.state.open ? (
              <i className="fa fa-times" />
            ) : (
              <i className="fa fa-bars" />
            )}
          </button>
        </div>
        <nav className={"navigation" + (this.state.open ? " open" : "")}>
          <a
            href="/"
            tabindex={this.state.open ? 0 : -1}
            className={this.getIsActive("/")}
            onClick={(e) => this.onNavigate(e, "/")}
          >
            <span>Home</span>
          </a>
          <a
            href="/leistungen"
            tabindex={this.state.open ? 0 : -1}
            className={this.getIsActive("/leistungen")}
            onClick={(e) => this.onNavigate(e, "leistungen")}
          >
            <span>Leistungen</span>
          </a>
          {/* <a
            href="/team"
            className={this.getIsActive("/team")}
            onClick={(e) => this.onNavigate(e, "team")}
          >
            <span>Team</span>
          </a> */}
          <a
            href="/ueber-mich"
            tabindex={this.state.open ? 0 : -1}
            className={this.getIsActive("/ueber-mich")}
            onClick={(e) => this.onNavigate(e, "ueber-mich")}
          >
            <span>Über mich</span>
          </a>        
        </nav>
      </header>
    );
  }
}

export default withRouter(Navigation);
